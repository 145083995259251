<template>
	<div class="company-add-edit">
		<div class="d-flex justify-content-between flex-wrap align-items-center pt-4 pb-3">
			<div class="h3 mb-0">{{$route.name === 'company-edit' ? $t("titles.editCompany") : $t("titles.createCompany")}}</div>
			<div class="btn-toolbar mb-2 mb-md-0">
				<small-button :text="$route.name === 'company-edit' ? $t('terms.update') : $t('terms.create')" :loader-active="loader" @click.native="attemptSubmit" />
			</div>
		</div>
		<spinner v-if="loader === true" />
		<template v-else>
			<form-container>
				<!--<field-input :label='$t("labels.name")' v-model="entity.name" :error="errors.name" />
				<field-input :label='$t("labels.vatNumber")' v-model="entity.cvr" :error="errors.cvr" />
				<field-input :label='$t("labels.billingEmail")' v-model="entity.billingEmail" :error="errors.billingEmail" />
				<field-input :label='$t("labels.billingName")' v-model="entity.billingName" :error="errors.billingName" />
				<field-input :label='$t("labels.billingAddress")' v-model="entity.billingAddress" :error="errors.billingAddress" />
				<field-input :label='$t("labels.billingAddress2")' v-model="entity.billingAddress2" :error="errors.billingAddress2" />
				<field-input :label='$t("labels.billingPostal")' v-model="entity.billingPostal" :error="errors.billingPostal" />
				<field-input :label='$t("labels.billingCountry")' v-model="entity.billingCountry" :error="errors.billingCountry" />
-->				<div class="part-label col-12">{{$t("titles.companyDetails")}}</div>
				<field-input :label='$t("labels.company")' id="company" v-model="entity.name" :error="errors.name" />
				<field-input :label='$t("labels.vatNumber")' id="cvr" v-model="entity.cvr" :error="errors.cvr" />
				<field-input :label='$t("labels.billingEmail")' id="billing-email" v-model="entity.billingEmail" :error="errors.billingEmail"  />
				<field-input :label='$t("labels.attPerson")'  id="att-person" v-model="entity.billingName" :error="errors.billingName" />
				<field-input :label='$t("labels.billingAddress")'  id="billing-address" v-model="entity.billingAddress" :error="errors.billingAddress"/>
				<field-input :label='$t("labels.postalZip")'  id="postal-zip" classes="col-4 col-md-2" v-model="entity.billingPostal" :error="errors.billingPostal"  />
				<field-input :label='$t("labels.city")'  id="city" classes="col-8 col-md-4" v-model="entity.billingCity" :error="errors.billingCity"  />
				<field-input :label='$t("labels.phone")'  id="Phone" v-model="entity.phone" :error="errors.phone"  />
				<field-country-select :label='$t("labels.country")'  id="Country" v-model="entity.billingCountry" :error="errors.billingCountry" />
				<template v-if="hasGlobalSuperAdmin">
					<field-input v-if="entity.type === companyTypes.PARTNER.type" class="col-12 col-md-6" :label='$t("labels.contract")' type="file"  id="file" v-model="contractFile" accept="application/pdf"  />
					<field-select class="col-12 col-md-6" :label="$t('labels.companyType')" v-model="entity.type" :error="errors.type" :options="[{value:companyTypes.COMPANY.type,text:$t(companyTypes.COMPANY.text)},{value:companyTypes.PARTNER.type,text:$t(companyTypes.PARTNER.text)}]"/>
					<div class="col-12 col-md-6">
						<field-checkbox v-model="entity.allowBooking" :error="errors.allowBooking">
							{{$t("labels.allowBooking")}}
						</field-checkbox>
					</div>
				</template>
			</form-container>


			<!--<regular-input label="Name" placeholder="Name" v-model="entity.name" :error="errors.name" />
			<regular-input label="CVR" placeholder="CVR" v-model="entity.cvr" :error="errors.cvr" />
			<regular-input label="Billing email" placeholder="Billing email" v-model="entity.billingEmail" :error="errors.billingEmail" />
			<regular-input label="Billing name" placeholder="Billing name" v-model="entity.billingName" :error="errors.billingName" />
			<regular-input label="Billing address" placeholder="Billing address" v-model="entity.billingAddress" :error="errors.billingAddress" />
			<regular-input label="Billing address 2" placeholder="Billing address 2" v-model="entity.billingAddress2" :error="errors.billingAddress2" />
			<regular-input label="Billing postal" placeholder="Billing postal" v-model="entity.billingPostal" :error="errors.billingPostal" />
			<regular-input label="Billing country" placeholder="Billing country" v-model="entity.billingCountry" :error="errors.billingCountry" />
			<div v-if="responseError !== ''" class="error w-mb-20">{{responseError}}</div>
			<standard-button :text="$route.name === 'company-edit' ? 'Update' : 'Create'" :loader-active="loader" @click.native="attemptSubmit" />-->
		</template>
	</div>
</template>

<script>

	import RegularInput from "@/components/inputs/RegularInput";
	import StandardButton from "@/components/buttons/StandardButton";
	import Spinner from "@/components/loaders/Spinner";
	import {mapState} from "vuex";
	import EntitySelect from "@/components/inputs/EntitySelect";
	import FormContainer from "@/components/container/FormContainer";
	import FieldInput from "@/components/inputs/FieldInput";
	import SmallButton from "@/components/buttons/SmallButton";
	import FieldCountrySelect from "@/components/inputs/FieldCountrySelect";
	import FieldCheckbox from "@/components/inputs/FieldCheckbox";
	import FieldSelect from "@/components/inputs/FieldSelect";
	import companyTypes from "@/companyTypes";
	import isGranted from "@/mixin/isGranted";
	import entityMap from "@/entityMap";

	export default {
		name: "AddEditCompany",
		mixins: [isGranted],
		components: {
			FieldSelect,
			FieldCountrySelect,
			EntitySelect, Spinner, StandardButton, RegularInput, FormContainer, FieldInput, SmallButton, FieldCheckbox},
		data(){
			return {
				contractFile: "",
				entity: {
			        name: "",
			        cvr: "",
			        phone: "",
			        billingEmail: "",
			        billingName: "",
			        billingAddress: "",
			        billingPostal: "",
			        billingCity: "",
			        billingCountry: "DK",
					allowBooking: true,
					contract: null,
					type: 0
		        },
		        errors: {
			        name: "",
			        cvr: "",
			        phone: "",
			        billingEmail: "",
			        billingName: "",
			        billingAddress: "",
			        billingPostal: "",
			        billingCity: "",
			        billingCountry: "",
					allowBooking: "",
					contract: "",
					type: ''
		        },
				responseError: '',
				loader: false
			}
		},
		computed: {
			...mapState('concrete', {
				companies: 'companies'
			}),
			companyTypes(){
				return companyTypes;
			},
			routeEntity(){
				return (typeof this.$route.params.id !== 'undefined' && typeof this.companies[this.$route.params.id] !== 'undefined') ? this.companies[this.$route.params.id] : false ;
			},
			hasError(){
				if(this.responseError !== '')
					return true;

				let error = false;

				Object.keys(this.errors).forEach((key) => {
					if(this.errors[key] !== '')
					{
						console.log(key)
						console.log(this.errors[key]);
						error = true;
					}
				});

				return error;
			}
		},
		methods: {
			buildPayload(){
				let payload = {
					entity: {
						'@type': 'Company'
					},
					method: (this.$route.name === 'company-edit') ? 'put' : 'post',
					payload: this.entity
				};

				if(this.$route.name === 'company-edit')
					payload.entity.id = this.$route.params.id;

				if(!this.hasGlobalSuperAdmin || this.entity.type !== this.companyTypes.PARTNER.type)
					delete payload.contract;

				return payload;
			},
			attemptSubmit(){
				if(this.loader) return;

				this.validateInputs();
				if(!this.hasError)
				{
					this.loader = true;
					this.responseError = '';
					if(this.contractFile !== '')
						this.updateContract()
					else
						this.dispatchUpdate();

				}
			},
			updateContract(){
				this.$store.dispatch('concrete/genericEntityRequest',this.buildMediaPayload())
				.then(response => {
					this.entity.contract = response['@id']
					this.dispatchUpdate();
				})
				.catch(error => {
					console.log(error)
					this.loader = false;
					this.fireGenericError();
				})
			},
			buildMediaPayload()
			{
				return {
					format: 'formData',
					entity: {
						'@type': 'MediaObject'
					},
					method: 'post',
					payload: {
						file: this.contractFile
					}
				}
			},
			dispatchUpdate(){
				this.$store.dispatch('concrete/genericEntityRequest',this.buildPayload())
				.then(result => {
					if(typeof result.id !== 'undefined')
					{
						if(this.$route.name === 'company-add')
						{
							this.$swal.fire({
								icon: 'success',
								title: this.$t("alerts.companyWasCreatedTitle"),
								text: this.$t("alerts.companyWasCreatedDescription"),
								confirmButtonText: this.$t("terms.ok"),
								confirmButtonColor: "#495760"
							}).then((result) => {
								if (result.isConfirmed) {
									this.$router.push({ name: 'entity-list', params: {entityType: 'companies',lang: this.$i18n.locale} })
								}
							});
						}
						else
						{
							this.$swal.fire({
								icon: 'success',
								title: this.$t("alerts.companyWasUpdatedTitle"),
								text: this.$t("alerts.companyWasUpdatedDescription"),
								confirmButtonText: this.$t("terms.ok"),
								confirmButtonColor: "#495760"
							}).then((response) => {
								if (response.isConfirmed) {
									this.$router.push({ name: 'company-view', params: {id: result.id,lang: this.$i18n.locale} })
								}
							});
						}
					}
					else
					{
						//Can this happen?
						console.log(result);
						this.fireGenericError();
					}
				})
				.catch(error => {
					console.log(error);
					if(typeof error.data !== 'undefined' && typeof error.data.error !== 'undefined')
					{
						// @todo Handle specific errors
						this.fireGenericError();

					}
					else
					{
						this.fireGenericError();
					}
				})
				.then(() => {
					this.loader = false;
				},() => {
					this.loader = false;
				})
			},
			validateInputs(){
				this.responseError = '';
				this.errors = {
			        name: "",
			        cvr: "",
			        phone: "",
			        billingEmail: "",
			        billingName: "",
			        billingAddress: "",
			        billingPostal: "",
			        billingCity: "",
			        billingCountry: "",
					allowBooking: "",
					contract: "",
					type: ''
				};
				Object.keys(this.errors).forEach(key => {
					if(key !== 'billingAddress2' && key !== 'contract' && this.entity[key] === '')
						this.errors[key] = this.$t("validation.pleaseFillTheField", {fieldName: key});
				})
			},
			fetchRouteEntity(){
				if(this.routeEntity === false)
				{
					this.loader = true;
					this.$store.dispatch('concrete/genericEntityRequest',{entity: {'id': this.$route.params.id,'@type':'Company'}})
					.then(result => {
						this.updateLocalValues();
					})
					.catch(error => {
						console.log(error);
					})
					.then(() => {
						this.loader = false;
					},() => {
						this.loader = false;
					})
				}
				else
				{
					this.updateLocalValues();
					this.loader = false;
				}
			},
			updateLocalValues(){
				Object.keys(this.entity).forEach(key => {
					this.entity[key] = this.routeEntity[key];
				});
			}
		},
		mounted()
		{
			if(typeof this.$route.params.id !== 'undefined')
			{
				this.fetchRouteEntity();
			}
		},
		watch:{
			$route(){
				if(typeof this.$route.params.id !== 'undefined')
				{
					this.fetchRouteEntity();
				}
			}
		}
	};
</script>

<style scoped>

</style>
